import SimpleDropdown from '@components/dropdown/simple';
import EllipsisTypography from '@components/ellipsis-typography';
import AudioWave from '@components/pages/side-kick-session/recorder/audio-wave';
import StyledButton from '@components/pages/side-kick-session/recorder/desktop/styles';
import Timer from '@components/pages/side-kick-session/recorder/LiveTimer';
import { useTranslation } from '@desygner/ui-common-translation';
import useTranscriptionService from '@hooks/useTranscriptionService';
import { useMediaDeviceSelect, useRoomContext } from '@livekit/components-react';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MicIcon from '@mui/icons-material/Mic';
import { Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneCallDialog, { GOOGLE_MEET_PHONE_KIND, JoinByPhoneType } from './PhoneCallDialog';
import { useDialogs } from '@toolpad/core';
import useAuth from '@hooks/useAuth';

const GOOGLE_MEET_CHANNEL = 'gmeet';
const CONNECT_PHONE_TOPIC = 'connect_phone';
const DISCONNECT_PHONE_TOPIC = 'disconnect_phone';
const MEET_ICON = 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Google_Meet_icon_%282020%29.svg/512px-Google_Meet_icon_%282020%29.svg.png'; // TODO

export default function Recorder() {
  const { stopTranscript } = useTranscriptionService();
  const room = useRoomContext();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const navigateTo = useNavigate();
  const { devices, activeDeviceId, setActiveMediaDevice } =
    useMediaDeviceSelect({
      kind: 'audioinput',
    });

  const { me } = useAuth();
  const dialogs = useDialogs();
  const [activeChannel, setActiveChannel] = useState<string | null>(activeDeviceId);
  const [phoneConnection, setPhoneConnection] = useState<JoinByPhoneType | null>(null);

  const channels = useMemo(() => {
    if (me?.alpha) {
      return [
        ...devices.map((device) => ({
          label: device.label,
          deviceId: device.deviceId,
        })),
        { label: 'Google Meet', deviceId: GOOGLE_MEET_CHANNEL },
      ];
    }

    return [...devices];
  }, [me, devices]);

  useEffect(() => {
    setActiveChannel(activeDeviceId);
  }, [activeDeviceId]);

  async function handleDeviceSelection(deviceId: string) {
    const encoder = new TextEncoder();

    if (activeChannel === GOOGLE_MEET_CHANNEL) {
      try {
        await confirm({
          title: t('page.sideKickSession.confirmDisconnectCall.title', {
            defaultValue: 'Disconnect ongoing call',
          }),
          description: t('page.sideKickSession.confirmDisconnectCall.content', {
            defaultValue: 'Are you sure you want to disconnect the ongoing call?',
          }),
          confirmationText: t('page.sideKickSession.confirmDisconnectCall.yes', {
            defaultValue: 'Yes, I want to select a new device',
          }),
          cancellationText: t('page.sideKickSession.confirmDisconnectCall.no', {
            defaultValue: 'No, keep call connected',
          }),
        });

        const encodedData = encoder.encode(JSON.stringify(phoneConnection));
        room.localParticipant.publishData(encodedData, {
          reliable: true,
          topic: DISCONNECT_PHONE_TOPIC,
        });

      } catch (err) {
        return;
      }
    }

    if (deviceId === GOOGLE_MEET_CHANNEL) {
      const phone = await dialogs.open(PhoneCallDialog, GOOGLE_MEET_PHONE_KIND);

      if (!phone) {
        if (activeChannel === GOOGLE_MEET_CHANNEL) {
          room.localParticipant.setMicrophoneEnabled(true);
          setActiveMediaDevice(devices[0].deviceId);
        }

        return;
      }

      room.localParticipant.setMicrophoneEnabled(false);

      const encodedData = encoder.encode(JSON.stringify(phone));
      room.localParticipant.publishData(encodedData, {
        reliable: true,
        topic: CONNECT_PHONE_TOPIC,
      });

      setActiveChannel(deviceId);
      setPhoneConnection(phone);
    } else {
      if (activeChannel === GOOGLE_MEET_CHANNEL) {
        room.localParticipant.setMicrophoneEnabled(true);
      }

      setActiveMediaDevice(deviceId);
    }
  }

  async function handleStopRecording() {
    try {
      await confirm({
        title: t('page.sideKickSession.confirmStopRecording.title', {
          defaultValue: 'Stop Recording Session',
        }),
        description: t('page.sideKickSession.confirmStopRecording.content', {
          defaultValue: 'Are you sure you want to stop recording?',
        }),
        confirmationText: t('page.sideKickSession.confirmStopRecording.yes', {
          defaultValue: 'Yes, I want to stop recording',
        }),
        cancellationText: t('page.sideKickSession.confirmStopRecording.no', {
          defaultValue: 'No, I want to continue',
        }),
      });

      stopTranscript();
      navigateTo(paths.sideKick.pathname); // TODO: this shouldn't be here
    } catch (err) {
      // empty
    }
  }

  const inputDevice = useMemo(
    () =>
      devices.find((device) => device.deviceId === activeChannel)?.label ||
      'Default',
    [activeChannel, devices],
  );

  return (
    <Card>
      <Stack direction="row" gap={2.5} sx={{ alignItems: 'center' }}>
        <StyledButton
          color="error"
          onClick={handleStopRecording}
          disableRipple
          disableTouchRipple
        >
          <AdjustOutlinedIcon fontSize="large" />
        </StyledButton>
        <Typography variant="body1" sx={{ md: 'block', xs: 'none' }}>
          {t('page.sideKickSession.recorder.recording', {
            defaultValue: 'Recording',
          })}
        </Typography>
        <Timer />
        <SimpleDropdown
          disablePortal
          options={
            channels.map((device) => ({
              text: device.label,
              value: device.deviceId,
              onClick: () => handleDeviceSelection(device.deviceId),
              icon: {
                iconStart: (
                  <>
                  {<CheckIcon
                    fontSize="small"
                    sx={{
                      visibility:
                      activeChannel === device.deviceId ? 'visible' : 'hidden',
                    }}
                  />}
                  {device.deviceId === GOOGLE_MEET_CHANNEL && <img width="16" height="13.19" src={MEET_ICON} />}
                  </>
                ),
            },
          }))}
          placement="top"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}
          trigger={{
            element: (
              <Button
                color="inherit"
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                size="small"
                endIcon={<ExpandMoreIcon />}
                startIcon={<MicIcon />}
                sx={{
                  width: 150,
                }}
              >
                <EllipsisTypography
                  variant="caption"
                  lineClamp={1}
                  title={inputDevice}
                >
                  {inputDevice || <Skeleton height={20} width={30} />}
                </EllipsisTypography>
              </Button>
            ),
          }}
        />
        <AudioWave />
      </Stack>
    </Card>
  );
}
