import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogProps } from '@toolpad/core';
import { useState } from 'react';
import { TextField } from '@mui/material';

export const CALL_PHONE_KIND = 'call';
export const GOOGLE_MEET_PHONE_KIND = 'google_meet';
export const ZOOM_PHONE_KIND = 'zoom';
export type PhonecallKindType = 'call' | 'google_meet' | 'zoom';
export type JoinByPhoneType = {
  phoneNumber: string;
  kind: PhonecallKindType;
  pin?: string;
  password?: string;
};

export default function PhoneCallDialog({
  open,
  onClose,
  payload,
}: DialogProps<PhonecallKindType, JoinByPhoneType | null>) {
  const [details, setDetails] = useState<JoinByPhoneType>({
    phoneNumber: '',
    kind: payload,
  });

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => onClose(null)}
    >
      <DialogTitle>
        <Typography variant="h3" component="span" sx={{ px: 2 }}>
          {/* TODO: support others */}
          Google Meet
        </Typography>
        <Typography sx={{ px: 2 }}>
          Provide details for Knowz to join a google meet call
        </Typography>
      </DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          size="small"
          placeholder="Meet phone number"
          fullWidth
          onChange={(e) => setDetails({
            ...details,
            phoneNumber: e.target.value
          })}
        />

        <TextField
          autoFocus
          size="small"
          placeholder="PIN"
          sx={{ mt: 2 }}
          fullWidth
          onChange={(e) => setDetails({
            ...details,
            pin: e.target.value
          })}
        />
      </DialogContent>

      <DialogActions sx={{ mt: 3 }}>
        <Button onClick={() => onClose(null)} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!details.phoneNumber || !details.pin}
          onClick={() => onClose(details)}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
